let normalPatterns = [
  {
    count: 3,
    cells: [
      [1]
    ]
  },
  {
    count: 3,
    cells: [
      [1, 1]
    ]
  },
  {
    count: 2,
    cells: [
      [1, 1],
      [0, 1]
    ]
  },
  {
    count: 2,
    cells: [
      [1, 1, 1]
    ]
  },
  {
    count: 1,
    cells: [
      [0, 1],
      [1, 1],
      [1, 0]
    ]
  },
  {
    count: 1,
    cells: [
      [1],
      [1],
      [1],
      [1]
    ]
  },
  {
    count: 1,
    cells: [
      [1, 1],
      [0, 1],
      [0, 1]
    ]
  },
  {
    count: 1,
    cells: [
      [0, 1],
      [1, 1],
      [0, 1]
    ]
  },
  {
    count: 1,
    cells: [
      [1, 1],
      [1, 1]
    ]
  },
  {
    count: 1,
    cells: [
      [1, 1],
      [0, 1],
      [1, 1]
    ]
  },
  {
    count: 1,
    cells: [
      [0, 1, 0],
      [1, 1, 1],
      [0, 1, 0]
    ]
  },
  {
    count: 1,
    cells: [
      [0, 1, 0],
      [0, 1, 0],
      [1, 1, 1]
    ]
  },
  {
    count: 1,
    cells: [
      [1, 1, 1],
      [1, 1, 0]
    ]
  },
  {
    count: 1,
    cells: [
      [1, 0, 0, 1],
      [1, 1, 1, 1]
    ]
  },
  {
    count: 1,
    cells: [
      [0, 1],
      [1, 1],
      [1, 1],
      [0, 1]
    ]
  },
  {
    count: 1,
    cells: [
      [0, 1, 0],
      [1, 1, 1],
      [0, 1, 1]
    ]
  },
  {
    count: 1,
    cells: [
      [0, 1, 1],
      [0, 1, 0],
      [1, 1, 0]
    ]
  },
  {
    count: 1,
    cells: [
      [1, 1, 1, 0],
      [0, 0, 1, 1]
    ]
  },
  {
    count: 1,
    cells: [
      [0, 1, 0],
      [1, 1, 1],
      [1, 0, 1]
    ]
  },
  {
    count: 1,
    cells: [
      [1, 1],
      [1, 1],
      [1, 1]
    ]
  },
  {
    count: 1,
    cells: [
      [1, 0, 0],
      [1, 0, 0],
      [1, 1, 1]
    ]
  },
  {
    count: 1,
    cells: [
      [0, 0, 1, 0, 0],
      [1, 1, 1, 1, 1]
    ]
  },
  {
    count: 1,
    cells: [
      [0, 1, 0],
      [1, 1, 1],
      [0, 1, 0],
      [0, 1, 0]
    ]
  },
  {
    count: 1,
    cells: [
      [1, 1, 1],
      [1, 1, 0],
      [1, 0, 0]
    ]
  },
  {
    count: 1,
    cells: [
      [1, 1, 1, 1],
      [0, 1, 0, 0]
    ]
  },
  {
    count: 1,
    cells: [
      [0, 1, 1],
      [1, 1, 0],
      [1, 0, 0]
    ]
  },
  {
    count: 1,
    cells: [
      [1, 1, 1, 1, 1],
      [0, 1, 0, 1, 0]
    ]
  },
  {
    count: 1,
    cells: [
      [0, 1, 0, 0],
      [0, 1, 0, 0],
      [1, 1, 1, 1],
      [0, 1, 0, 0]
    ]
  },
  {
    count: 1,
    cells: [
      [0, 1, 1],
      [1, 1, 1],
      [1, 1, 0]
    ]
  },
  {
    count: 1,
    cells: [
      [1, 0, 1],
      [1, 0, 1],
      [1, 1, 1]
    ]
  },
  {
    count: 1,
    cells: [
      [0, 1, 0],
      [1, 1, 1],
      [1, 1, 1]
    ]
  },
  {
    count: 1,
    cells: [
      [1, 0, 1],
      [1, 1, 1],
      [1, 0, 1]
    ]
  },
  {
    count: 1,
    cells: [
      [1, 0, 0, 0],
      [1, 1, 0, 0],
      [0, 1, 1, 0],
      [0, 0, 1, 1]
    ]
  },
  {
    count: 1,
    cells: [
      [1, 1, 0, 0],
      [0, 1, 1, 1],
      [1, 1, 0, 0]
    ]
  }
]

let startingPatterns = [
  {
    count: 1,
    cells: [
      [1, 0, 0, 1],
      [0, 1, 1, 0],
      [0, 1, 1, 0],
      [1, 0, 0, 1]
    ]
  },
  {
    count: 1,
    cells: [
      [0, 0, 1, 1],
      [0, 0, 0, 1],
      [0, 0, 0, 1],
      [1, 1, 1, 1]
    ]
  },
  {
    count: 1,
    cells: [
      [0, 0, 0, 1],
      [0, 0, 1, 1],
      [0, 1, 1, 0],
      [1, 1, 0, 0],
      [1, 0, 0 ,0]
    ]
  },
  {
    count: 1,
    cells: [
      [0, 0, 1],
      [0, 1, 1],
      [1, 1, 0],
      [0, 1, 1],
      [0, 0, 1]
    ]
  },
  {
    count: 1,
    cells: [
      [1, 1],
      [0, 1],
      [1, 1],
      [0, 1],
      [1, 1]
    ]
  },
  {
    count: 1,
    cells: [
      [0, 1, 0],
      [1, 1, 1],
      [1, 1, 1],
      [0, 1, 0]
    ]
  },
  {
    count: 1,
    cells: [
      [1, 1, 0],
      [0, 1, 1],
      [1, 1, 0],
      [0, 1, 1]
    ]
  },
  {
    count: 1,
    cells: [
      [0, 1, 0],
      [1, 1, 1],
      [0, 1, 0],
      [1, 1, 1]
    ]
  },
  {
    count: 1,
    cells: [
      [1, 1, 0],
      [0, 1, 1],
      [0, 1, 1],
      [1, 1, 0]
    ]
  },
  {
    count: 1,
    cells: [
      [0, 0, 1, 1],
      [1, 1, 1, 1],
      [0, 0, 1, 0],
      [0, 0, 1, 0]
    ]
  },
  {
    count: 1,
    cells: [
      [1, 1, 1],
      [1, 0, 1],
      [1, 1, 1]
    ]
  },
  {
    count: 1,
    cells: [
      [0, 1, 0],
      [1, 1, 1],
      [1, 0, 1],
      [1, 0, 1]
    ]
  },
  {
    count: 1,
    cells: [
      [1, 1, 1],
      [0, 1, 0],
      [0, 1, 0],
      [1, 1, 1]
    ]
  }
]

export const patterns = {startingPatterns: startingPatterns, normalPatterns: normalPatterns}
